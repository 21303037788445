import { useStore } from '@store/store';
import { useQuery } from '@tanstack/react-query';
import { bridgeCardano } from '@utils/bridge-cardano';
import { bridgeEvm } from '@utils/bridge-evm';
import { CARDANO_NETWORK } from 'src/contants/cardano';
import { shallow } from 'zustand/shallow';
import { useEthersSigner } from './use-ethers-signer';
import { BigNumber, providers } from 'ethers';
import { useNativeToken } from './use-native-token';
import { ValueInputError } from '@typings/ValueInputError';
import { useBridgeTokenData } from './use-bridge-token-data';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { formatCryptoBalance } from '@utils/format-currency';
import { BNDecimals } from '@utils/format-currency/big-number';
import { useSelectedNfts } from './use-selected-nfts';
import { SupportedWallet } from '@typings/wallet';
import { CardanoNft, EvmNft } from '@api/meld-app/nfts/nfts-query.types';
import { parseCardanoAsset } from '@utils/parse-cardano-asset';
import { IS_MOCK_ENV } from 'src/contants/is-mock-env';
import { getBridgeFee } from '@api/bridge/get-fee';
import { queryClient } from '@api/query-client';
import { CALCULATE_FEE_QUERY_KEY } from 'src/contants/calculate-fee';
import { Timeout } from 'react-number-format/types/types';
import { MELD_NETWORK } from 'src/contants/meld';
import { NetworkChainType } from '@api/meld-app/networks/networks.types';
import { useUnsupportedExtNetwork } from '@hooks/use-unsupported-ext-network';
import { getNetworkId } from '@utils/get-network-id';
import { captureError } from '@utils/metrics';
import { useEffect, useRef } from 'react';

// timeout gets cleared in useIsBridging() when a bridge is in progress
export const CALCULATE_FEE_TIMEOUT_DATA: { timeoutToRecalculateBridgeBackFee: Timeout | null } = {
  timeoutToRecalculateBridgeBackFee: null,
};

export const useCalculateTxFee = () => {
  const selectedWalletToken = useStore((state) => state.selectedWalletToken, shallow);
  const { amount, inputError } = useStore((state) => state.inputData, shallow);
  const { cardanoAddress } = useStore((state) => state.cardanoData, shallow);
  const { evmAddress } = useStore((state) => state.evmData, shallow);
  const externalWalletData = useStore((state) => state.externalWalletData, shallow);

  const setBridgeData = useStore((state) => state.setBridgeData);
  const setEvmData = useStore((state) => state.setEvmData);
  const setInputData = useStore((state) => state.setInputData);
  const evmWallet = useEthersSigner();
  const { wrongEvmNetwork, wrongCardanoNetwork } = useUnsupportedExtNetwork();

  const bridgeTokenData = useBridgeTokenData();
  const { selectedNftWallet, nftBridgeSelected, selectedNftObjects } = useSelectedNfts();

  const nativeToken = useNativeToken({
    network: nftBridgeSelected
      ? selectedNftObjects?.[0]?.chainType === NetworkChainType.CARDANO
        ? CARDANO_NETWORK
        : MELD_NETWORK
      : selectedWalletToken?.network,
  });

  // store a ref to the amount and keep it up to date to be checked inside the query below
  const amountRef = useRef(amount);

  useEffect(() => {
    amountRef.current = amount;
  }, [amount]);

  const { isLoading, fetchStatus, isRefetching } = useQuery(
    [
      CALCULATE_FEE_QUERY_KEY,
      selectedWalletToken?.tokenId,
      amount,
      nftBridgeSelected,
      selectedNftObjects,
      selectedNftWallet,
      externalWalletData.chainType,
      cardanoAddress,
      evmAddress,
      wrongEvmNetwork,
      wrongCardanoNetwork,
    ],
    async () => {
      if (IS_MOCK_ENV) {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(null);
            setBridgeData({
              transactionCost: ' 0.001',
            });
          }, 500);
        });
      }

      const calculatingFeeForNft = useStore.getState().nftBridgeSelected;

      const isNoNftSelected = () => !Object.values(useStore.getState().selectedNfts).filter((a) => a).length;

      if (CALCULATE_FEE_TIMEOUT_DATA.timeoutToRecalculateBridgeBackFee)
        clearTimeout(CALCULATE_FEE_TIMEOUT_DATA.timeoutToRecalculateBridgeBackFee);

      setBridgeData({ transactionCost: '', feeAmount: '', notEnoughToken: false });

      if (inputError === ValueInputError.NOT_ENOUGH_NATIVE_TOKEN) setInputData({ inputError: null });

      // do not calculate fee if we're bridging but dont have an evm wallet connected
      if (!evmAddress && externalWalletData?.chainType !== NetworkChainType.EVM) return null;

      let isUserTransferingFullAmount = false;

      let fee = '0';
      if (
        (!nftBridgeSelected && selectedWalletToken?.network === CARDANO_NETWORK) ||
        (selectedNftWallet && selectedNftWallet !== SupportedWallet.EVM)
      ) {
        isUserTransferingFullAmount =
          nftBridgeSelected || !selectedWalletToken?.isNative
            ? false
            : Boolean(
                formatCryptoBalance(amount, selectedWalletToken?.symbol) ===
                  formatCryptoBalance(selectedWalletToken?.amount ?? '0', selectedWalletToken?.symbol),
              );

        try {
          // if the user is transferring their full cardano amount we deduct 1.5 ADA to be able to pay for tx fees
          const realAmount = isUserTransferingFullAmount
            ? BNDecimals(amount).minus(BNDecimals(1.5)).toString()
            : amount;

          const assetData =
            nftBridgeSelected || selectedWalletToken?.isNative
              ? {}
              : parseCardanoAsset(selectedWalletToken?.contract ?? '');

          fee = await bridgeCardano({
            amount: realAmount,
            ...assetData,
            estimateCost: true,
            nfts:
              nftBridgeSelected && selectedNftWallet !== SupportedWallet.EVM
                ? (selectedNftObjects as Array<CardanoNft>).map((a) => parseCardanoAsset(a.contract))
                : undefined,
          });

          if (amountRef.current !== amount) {
            // return null because this query does not matter (running for an old inputted value
            return null;
          }

          if (calculatingFeeForNft && isNoNftSelected()) return null;

          if (+realAmount > 0 && !nftBridgeSelected) {
            // this is a hack to ensure we don't set this for the wrong amount (given this query runs whenever the amount changes)
            if (amountRef.current === amount) {
              setInputData({ realAmount });
            }
            // return null because this query does not matter (running for an old inputted value)
            else return null;
          }
        } catch (err) {
          if (amountRef.current !== amount) {
            // return null because this query does not matter (running for an old inputted value
            return null;
          }
          captureError(err);
          if (!inputError) {
            setInputData({ inputError: ValueInputError.NOT_ENOUGH_NATIVE_TOKEN });
          }
        }
      } else {
        // do not calculate fee if we're bridging to cardano but dont have a cardano wallet connected
        if (
          bridgeTokenData.tokenData.destinationToken.network === CARDANO_NETWORK &&
          !cardanoAddress &&
          externalWalletData?.chainType !== NetworkChainType.CARDANO
        )
          return null;
        setEvmData({ evmRequiresApproval: undefined });
        try {
          isUserTransferingFullAmount = nftBridgeSelected
            ? false
            : Boolean(
                formatCryptoBalance(amount, selectedWalletToken?.symbol) ===
                  formatCryptoBalance(selectedWalletToken?.amount ?? '0', selectedWalletToken?.symbol),
              );

          const isBridgingBackNFT = nftBridgeSelected && selectedNftObjects[0].network !== CARDANO_NETWORK;

          // Get bridge fee data
          const { feeInfo, amount: realBridgeAmount } =
            selectedWalletToken?.toBridgeBack || isBridgingBackNFT
              ? await getBridgeFee({
                  network: getNetworkId(bridgeTokenData.tokenData.destinationToken.network as string),
                  token: isBridgingBackNFT ? selectedNftObjects[0].contract : selectedWalletToken?.contract ?? '',
                  amount: isBridgingBackNFT ? '0' : parseUnits(amount, selectedWalletToken?.decimals ?? 18).toString(),
                  tokenId: isBridgingBackNFT ? (selectedNftObjects[0] as EvmNft).tokenId : undefined,
                })
              : { amount: '', feeInfo: { signature: '', feeAmount: '', deadline: '' } };

          if (calculatingFeeForNft && isNoNftSelected()) return null;

          if (amountRef.current !== amount) {
            // return null because this query does not matter (running for an old inputted value
            return null;
          }

          // recalculate fee every 30 seconds if a deadline exists
          if (feeInfo.deadline) {
            CALCULATE_FEE_TIMEOUT_DATA.timeoutToRecalculateBridgeBackFee = setTimeout(() => {
              !useStore.getState().bridgeData.initiatedBridge &&
                !useStore.getState().bridgeData.approving &&
                (useStore.getState().selectedWalletToken?.toBridgeBack || useStore.getState().nftBridgeSelected) &&
                queryClient.invalidateQueries([CALCULATE_FEE_QUERY_KEY]);
            }, 60000);
          }

          const amountToBridge =
            selectedWalletToken?.toBridgeBack && !isBridgingBackNFT
              ? parseUnits(amount, selectedWalletToken.decimals).toBigInt()
              : isBridgingBackNFT
                ? 0n
                : parseUnits(amount, selectedWalletToken?.decimals ?? 18).toBigInt();

          const amountToBridgePlusFee =
            selectedWalletToken?.toBridgeBack && !isBridgingBackNFT
              ? formatUnits(
                  BigNumber.from(amountToBridge).add(BigNumber.from(feeInfo.feeAmount)),
                  selectedWalletToken?.decimals,
                ).toString()
              : isBridgingBackNFT
                ? formatUnits(feeInfo.feeAmount, 18)
                : amount;

          // if the user is trying to bridge the full amount get the cost of the tx for the min possible amount
          const amountToPassToContract =
            isUserTransferingFullAmount && selectedWalletToken?.isNative
              ? formatUnits('1', selectedWalletToken?.decimals).toString()
              : isBridgingBackNFT
                ? amountToBridgePlusFee
                : formatUnits(amountToBridge, selectedWalletToken?.decimals).toString();

          const { cost, requiresApproval } = await bridgeEvm({
            isTransferingFullNativeAmount: selectedWalletToken?.isNative && isUserTransferingFullAmount,
            wallet: evmWallet as providers.JsonRpcSigner,
            amount: amountToPassToContract,
            setFeeData: (feeData) => {
              if (amountRef.current !== amount) {
                // return null because this query does not matter (running for an old inputted value
                return null;
              }

              if (calculatingFeeForNft && isNoNftSelected()) return null;
              setEvmData({ evmTxFeeData: feeData });
            },
            destinationNetwork: bridgeTokenData.tokenData.destinationToken.network,
            contract: nftBridgeSelected ? selectedNftObjects[0].contract : selectedWalletToken?.contract ?? '',
            tokenId: nftBridgeSelected ? (selectedNftObjects[0] as EvmNft).tokenId : undefined,
            bridgeFeeData: selectedWalletToken?.toBridgeBack || isBridgingBackNFT ? { feeInfo } : undefined,
          });

          if (calculatingFeeForNft && isNoNftSelected()) return null;

          if (amountRef.current !== amount) {
            // return null because this query does not matter (running for an old inputted value
            return null;
          }

          // if bridging back but user can't cover total amount, set error
          if (
            !requiresApproval &&
            selectedWalletToken?.toBridgeBack &&
            !isBridgingBackNFT &&
            +selectedWalletToken.amount < +amountToBridgePlusFee
          ) {
            setEvmData({ evmRequiresApproval: false });
            setBridgeData({
              notEnoughToken: true,
              realBridgeAmount,
              deadline: feeInfo.deadline,
              feeAmount: feeInfo.feeAmount,
              signature: feeInfo.signature,
            });
            return null;
          }
          // if bridging back NFT but can't afford bridge fee show native token error
          else if (!requiresApproval && isBridgingBackNFT && +(nativeToken?.amount ?? 0) < +amountToBridgePlusFee) {
            setEvmData({ evmRequiresApproval: false });
            setBridgeData({
              realBridgeAmount,
              deadline: feeInfo.deadline,
              feeAmount: feeInfo.feeAmount,
              signature: feeInfo.signature,
              notEnoughToken: true,
            });
            return null;
          }

          // push bridge fee data to global state
          if (selectedWalletToken?.toBridgeBack || isBridgingBackNFT)
            setBridgeData({
              realBridgeAmount,
              deadline: feeInfo.deadline,
              feeAmount: feeInfo.feeAmount,
              signature: feeInfo.signature,
            });

          setEvmData({ evmRequiresApproval: requiresApproval ?? false });

          // only applies if user is not bridging back - we get the amount from the BE in that case
          if (
            selectedWalletToken?.isNative &&
            isUserTransferingFullAmount &&
            cost &&
            !nftBridgeSelected &&
            !selectedWalletToken?.toBridgeBack
          ) {
            // only set this if the token is native, as it affects the gas we set when submitting the tx
            setInputData({ isTransferingFullNativeAmount: true });
            // use WEI amount
            const realAmount = BNDecimals(selectedWalletToken?.amount ?? '0').minus(BNDecimals(cost));
            if (+realAmount.toString() > 0) setInputData({ realAmount: realAmount.toString() });
          } else {
            setInputData({ isTransferingFullNativeAmount: false, realAmount: '' });
          }

          fee = cost as string;
        } catch (err) {
          if (amountRef.current !== amount) {
            // return null because this query does not matter (running for an old inputted value
            return null;
          }
          captureError(err);
          if (!inputError) {
            setInputData({ inputError: ValueInputError.NOT_ENOUGH_NATIVE_TOKEN });
          }
        }
      }

      let isOverSendableAmount = false;

      if (selectedWalletToken?.isNative) {
        isOverSendableAmount = +amount + Number(fee) > +(nativeToken?.amount ?? 0);
      } else if (!selectedWalletToken?.isNative) {
        isOverSendableAmount = Number(fee) > +(nativeToken?.amount ?? 0);
      }

      if (isOverSendableAmount && !inputError) {
        setInputData({ inputError: ValueInputError.NOT_ENOUGH_NATIVE_TOKEN });
      } else if (!isOverSendableAmount && inputError === ValueInputError.NOT_ENOUGH_NATIVE_TOKEN) {
        setInputData({ inputError: null });
      }

      setBridgeData({
        transactionCost: fee,
      });

      return fee;
    },
    {
      refetchOnWindowFocus: false,
      enabled:
        !!((+amount > 0 && !!selectedWalletToken) || (nftBridgeSelected && selectedNftObjects.length)) &&
        !!(evmWallet || externalWalletData?.chainType === NetworkChainType.EVM) &&
        // selected CARDANO NFT and correct network selected
        (nftBridgeSelected && selectedNftObjects[0].chainType === NetworkChainType.CARDANO
          ? !wrongCardanoNetwork
          : true) &&
        // selected EVM NFT and correct network selected
        (nftBridgeSelected && selectedNftObjects[0].chainType === NetworkChainType.EVM ? !wrongEvmNetwork : true) &&
        !wrongEvmNetwork,
      refetchOnMount: false,
      staleTime: 0,
      cacheTime: 0,
    },
  );

  return {
    isLoading: (isLoading && fetchStatus !== 'idle') || (isRefetching && fetchStatus !== 'idle'),
  };
};
